
import * as prismicRuntime$Agn7o2Q3zl from '/opt/build/repo/front/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/prismic'
import * as imgixRuntime$ON6NiXtxuA from '/opt/build/repo/front/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/imgix'

export const imageOptions = {
  "screens": {
    "xs": 540,
    "sm": 720,
    "md": 960,
    "lg": 1280,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgix",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['prismic']: { provider: prismicRuntime$Agn7o2Q3zl, defaults: {} },
  ['imgix']: { provider: imgixRuntime$ON6NiXtxuA, defaults: {"baseURL":""} }
}
        