import revive_payload_client_4uSZmDU6Pm from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5pvswtEsPX from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sOm221bfXC from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_QewS7qGD6Q from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UGEzoK80d9 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VuyBymOYGS from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4BWZp6kd69 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ra6Ou8bQzo from "/opt/build/repo/front/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/front/.nuxt/components.plugin.mjs";
import prefetch_client_2qATYwyKKh from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_sass@1.80.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IfcldbFAwz from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_MnFmwDrkcO from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_f4xBaMRnAv from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
export default [
  revive_payload_client_4uSZmDU6Pm,
  unhead_5pvswtEsPX,
  router_sOm221bfXC,
  payload_client_QewS7qGD6Q,
  navigation_repaint_client_UGEzoK80d9,
  check_outdated_build_client_VuyBymOYGS,
  chunk_reload_client_4BWZp6kd69,
  plugin_vue3_Ra6Ou8bQzo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2qATYwyKKh,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  plugin_client_MnFmwDrkcO,
  plugin_f4xBaMRnAv
]