import { default as PrismicPreviewM7AT6n01xHMeta } from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as about_45uslU1iiwo303Meta } from "/opt/build/repo/front/pages/about-us.vue?macro=true";
import { default as _91uid_931YDEzst6z8Meta } from "/opt/build/repo/front/pages/blog/[uid].vue?macro=true";
import { default as _91uid_93mMDejrvZnRMeta } from "/opt/build/repo/front/pages/blog/articles/[uid].vue?macro=true";
import { default as indexP5dlkx1H4WMeta } from "/opt/build/repo/front/pages/blog/index.vue?macro=true";
import { default as z_45text_45input0UJ9ToRiI8Meta } from "/opt/build/repo/front/pages/components/z-text-input.vue?macro=true";
import { default as contacto4pMYxKcsrMeta } from "/opt/build/repo/front/pages/contact.vue?macro=true";
import { default as faqPcBd4HGBoeMeta } from "/opt/build/repo/front/pages/faq.vue?macro=true";
import { default as indexK09NvBaU2LMeta } from "/opt/build/repo/front/pages/index.vue?macro=true";
import { default as not_45foundaRJXFAEO1ZMeta } from "/opt/build/repo/front/pages/not-found.vue?macro=true";
import { default as propertiesM9onkWQB19Meta } from "/opt/build/repo/front/pages/properties.vue?macro=true";
import { default as slice_45simulatorXrK22py1fHMeta } from "/opt/build/repo/front/pages/slice-simulator.vue?macro=true";
export default [
  {
    name: "prismic-preview___ko",
    path: "/preview",
    component: () => import("/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "prismic-preview___de",
    path: "/de/preview",
    component: () => import("/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "about-us___ko",
    path: "/about-us",
    component: () => import("/opt/build/repo/front/pages/about-us.vue")
  },
  {
    name: "about-us___de",
    path: "/de/about-us",
    component: () => import("/opt/build/repo/front/pages/about-us.vue")
  },
  {
    name: "blog-uid___ko",
    path: "/blog/:uid()",
    component: () => import("/opt/build/repo/front/pages/blog/[uid].vue")
  },
  {
    name: "blog-uid___de",
    path: "/de/blog/:uid()",
    component: () => import("/opt/build/repo/front/pages/blog/[uid].vue")
  },
  {
    name: "blog-articles-uid___ko",
    path: "/blog/articles/:uid()",
    component: () => import("/opt/build/repo/front/pages/blog/articles/[uid].vue")
  },
  {
    name: "blog-articles-uid___de",
    path: "/de/blog/articles/:uid()",
    component: () => import("/opt/build/repo/front/pages/blog/articles/[uid].vue")
  },
  {
    name: "blog___ko",
    path: "/blog",
    component: () => import("/opt/build/repo/front/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/opt/build/repo/front/pages/blog/index.vue")
  },
  {
    name: "components-z-text-input___ko",
    path: "/components/z-text-input",
    meta: z_45text_45input0UJ9ToRiI8Meta || {},
    component: () => import("/opt/build/repo/front/pages/components/z-text-input.vue")
  },
  {
    name: "components-z-text-input___de",
    path: "/de/components/z-text-input",
    meta: z_45text_45input0UJ9ToRiI8Meta || {},
    component: () => import("/opt/build/repo/front/pages/components/z-text-input.vue")
  },
  {
    name: "contact___ko",
    path: "/contact",
    component: () => import("/opt/build/repo/front/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/opt/build/repo/front/pages/contact.vue")
  },
  {
    name: "faq___ko",
    path: "/faq",
    component: () => import("/opt/build/repo/front/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/opt/build/repo/front/pages/faq.vue")
  },
  {
    name: "index___ko",
    path: "/",
    component: () => import("/opt/build/repo/front/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/build/repo/front/pages/index.vue")
  },
  {
    name: "not-found___ko",
    path: "/not-found",
    component: () => import("/opt/build/repo/front/pages/not-found.vue")
  },
  {
    name: "not-found___de",
    path: "/de/not-found",
    component: () => import("/opt/build/repo/front/pages/not-found.vue")
  },
  {
    name: "properties___ko",
    path: "/properties",
    component: () => import("/opt/build/repo/front/pages/properties.vue")
  },
  {
    name: "properties___de",
    path: "/de/properties",
    component: () => import("/opt/build/repo/front/pages/properties.vue")
  },
  {
    name: "slice-simulator___ko",
    path: "/slice-simulator",
    component: () => import("/opt/build/repo/front/pages/slice-simulator.vue")
  },
  {
    name: "slice-simulator___de",
    path: "/de/slice-simulator",
    component: () => import("/opt/build/repo/front/pages/slice-simulator.vue")
  }
]